//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ar } from "vue2-datepicker/locale/ar";
import { en } from "vue2-datepicker/locale/en";
import EmptyState from '@/components/shared/EmptyState.vue'


export default {
    name: "GeneralNews",
    components: { DatePicker, EmptyState },
    data() {
        return {
            isCheck: false,
            ar: ar,
            en: en,
            lang: localStorage.lang,
            total_pages: 0,
            total_rows: 0,
            newsList: [],
            newsPushList: [],
            newsCategoryList: [],
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: process.env.VUE_APP_Default_Page_Size,
            },
            Filter: {},
            isLoading: false
        };
    },

    mounted() {
        let self = this;
        self.load();
        self.axios
            .get(process.env.VUE_APP_APIEndPoint + "NewsCategory/GetAllCategory")
            .then((response) => {
                self.newsCategoryList = response.data.Data;
                setTimeout(function () { }, 100);
            });
    },
    methods: {
        load() {
            let self = this;

            if (!self.isLoading && !self.isCheck) {
                self.isLoading = true;
                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "GeneralNews/PublicGetAll", self.query)
                    .then(function (res) {
                        self.newsPushList = res.data.Data;
                        self.newsList.push(...self.newsPushList);
                        self.query.PageNumber = res.data.PageNumber;
                        self.query.PageSize = res.data.PageSize;
                        self.total_rows = res.data.Total;
                        self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
                        if (self.total_pages > 0 && self.query.PageNumber <= 1) {
                            setTimeout(function () { }, 100);
                        }
                        self.isCheck = false;
                        if (self.total_pages <= self.query.PageNumber) self.isCheck = true;

                    })
                    .finally(() => self.isLoading = false);
            }

        },
        formatDate(value) {
            if (localStorage.lang == 'ar') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("ar-EG-u-nu-latn", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }

            else if (localStorage.lang == 'en') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("default", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }

        },

        Scroll() {
            let self = this;
            self.query.PageNumber++;
            self.load();
        },

        Search: function () {
            let self = this;
            self.query.Filter = [];
            if (self.Filter.Title && self.Filter.Title != "") {
                self.query.Filter.push({
                    FieldName: "Title",
                    Operation: "=",
                    value: self.Filter.Title,
                });
            }
            if (self.Filter.StartDate && self.Filter.StartDate != "") {
                self.query.Filter.push({
                    FieldName: "FromDate",
                    Operation: "=",
                    value: self.Filter.StartDate,
                });
            }
            if (self.Filter.EndDate && self.Filter.EndDate != "") {
                self.query.Filter.push({
                    FieldName: "ToDate",
                    Operation: "=",
                    value: self.Filter.EndDate,
                });
            }

            if (self.Filter.NewsCategoryId && self.Filter.NewsCategoryId.length > 0) {
                self.query.Filter.push({
                    FieldName: "NewsCategoryId",
                    Operation: "in",
                    value: self.Filter.NewsCategoryId.toString(),
                });
            }

            self.query.PageNumber = 1;
            self.newsList = [];
            self.isCheck = false;
            self.load();
        },
        handleScroll(event) {
            let infiniteList = document.getElementById("infinite-list");
            if (infiniteList) {
                let listPosition = infiniteList.offsetTop + infiniteList.offsetHeight;

                if (window.scrollY >= listPosition - 600) {
                    this.Scroll();
                }
            }
        },

    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
